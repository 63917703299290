import * as THREE from 'three';
import './style/main.css';
import Phaser from "phaser";
import Global from './global';

const sizes = {};
var gameState = 0;
sizes.width = window.innerWidth;
sizes.height = window.innerHeight;
var spinTime = 4000;
var spinningStarted = false;
var spinEnded = false;
var stopPos = [];
var meshes;
var SLOT_NUMBER = 4;
var material;
var view;
var x = 1000;
var camera, scene, renderer;
var geometry;
var symbolsArrAfterSpin = [];
var loadBar;
var isWon, winSymbolIndex;
var currentSpinRound = 0;
var loader = null;
var points = 300;

var gameMode = "play";
var isGameStarted = false;
var isGameOver = false

//cheatSheet Variable 
var triggerWinMannually = false;

var xRotationArr = [54.2, 7.2, 15,55.8];
var spinnedSymbols = [];
var sceneGame;

var sceneMode;

var gInstance = new Global();


class Game extends Phaser.Game {
     constructor() {
         super(config);
         this.scene.start("load");
     }
}

 
 class LoadScene extends Phaser.Scene {

    constructor() {
        console.log("load")
        super({
            key: 'Load',
            pack: {
                files: [{
                    type: 'image', key: "logo", url: gInstance.getLogoUrl()
                },
                { type: "image", key: "bg", url: gInstance.getBgUrl()}]
            }
        });
    }

    preload() {
        this.loadBg = this.add.image(this.cameras.main.width * .5, this.cameras.main.height * .5, "bg").setScale(2);
        this.logo = this.add.image(this.cameras.main.width * .5, this.cameras.main.height * .3, "logo").setScale(.5);


        let progressBar = this.add.graphics();
        let progressBox = this.add.graphics();
        progressBox.fillStyle(0x222222, 0.1);
        progressBox.fillRect(
            config.width / 2 - 150,
            config.height / 2 - 30,
            320,
            50
        )

        //PERCENT TEXT
        let percentText = this.make.text({
            x: config.width / 2,
            y: config.height / 2 - 5,
            text: "0%",
            style: {
                font: "20px monospace",
                fill: "#222222"
            },
        })
        percentText.setOrigin(0.5, 0.5);

        this.load.on("progress", function (value) {
            progressBar.clear();
            progressBar.fillStyle(0x3171B7, 1);
            progressBar.fillRect(
                config.width / 2 - 140,
                config.height / 2 - 20,
                300 * value,
                30
            )
            percentText.setText(parseInt(value * 100) + " %");
        });

        this.load.on("fileprogress", function (file) { });
        this.loadComplete = false;
        var _self = this;
        this.load.on("complete", function () {
            progressBar.destroy();
            progressBox.destroy();
            percentText.destroy();
            this.loadComplete = true;
            _self.logo.destroy();
            _self.loadBg.destroy();

        });

        this.load.image("plainBg","assets/plainBg.png");

        //Rect Images
        this.load.image("rectImage", gInstance.getRectMat());

        this.load.image("bg", gInstance.getBgUrl());
        this.load.image("logo", gInstance.getLogoUrl());
        this.load.image("howtoplayPanel",gInstance.howToPlayPanel())

        this.load.image("panelBg",gInstance.getPanelBg())

        this.load.image("playNow",gInstance.getPlayNowBtn())

        this.load.image("slotBG",gInstance.getSlotBG())

        this.load.image("leftArrow",gInstance.getArrow());

        this.load.image("spinBtn",gInstance.getSpinBtn())

        this.load.image("tryAgainPng",gInstance.getTryAgain())

        this.load.image("uiBoard", gInstance.getUIBoard());

        this.load.image("heartIcon",gInstance.getAttemptsHeart())

        this.load.image("popupPanel",gInstance.popUpPanel());

        this.load.image("cancelBtn",gInstance.cancelBtn());

        this.load.image("confirmBtn",gInstance.confirmBtn())

        this.load.image("winPopup",gInstance.getWinPopup())

        this.load.image("greyOverlay",gInstance.getGreyOverlay());


        this.load.image("uberCoupon",gInstance.getUberReward());
        this.load.image("gaanaCoupon",gInstance.getGaanaReward());
        this.load.image("dinnerCoupon",gInstance.getDinnerReward())
        this.load.image("zomatoCoupon",gInstance.getZomatoReward());
        this.load.image("swiggyCoupon",gInstance.getSwiggyReward())
        this.load.image("tripCoupon",gInstance.getMakeMyTripReward());



        this.load.image("gameModeIcon",gInstance.getGameModeIcon());
        this.load.image("rewardModeIcon",gInstance.getRewardModeIcon())

        this.load.image("bulletsPoint",gInstance.getBullets());

        this.load.image("coins",gInstance.getCoins());

    }

    create() {
        this.scene.start("Game");

    }
}





class GameScene extends Phaser.Scene {

    constructor() {
      super("Game")
    }

    create() {
        var _self = this;
        this.bg = this.add.image(this.cameras.main.width * .5, this.cameras.main.height * .5, "bg").setScale(2);
        this.logo = this.add.image(this.cameras.main.width * .5, this.cameras.main.height * .15, "logo").setScale(.5);

        this.howToPlayPanel = this.add.image(this.cameras.main.width * .5, this.cameras.main.height * .5, "howtoplayPanel").setScale(0.25)

        this.playNowBtn = this.add.image(this.cameras.main.width * .5,this.cameras.main.height * .64, "playNow").setScale(0.5).setInteractive().on('pointerdown', () => {
            _self.fadeHowToPlayScreen();
        });

        this.gamePlayBG = this.add.image(this.cameras.main.width * .5, this.cameras.main.height * .5, "bg").setScale(1.7).setAlpha(0);

        this.giftArr = ["uberCoupon","gaanaCoupon","dinnerCoupon","zomatoCoupon","swiggyCoupon","tripCoupon"];

        this.addHowToPlayTextContents();
    }


    addHowToPlayTextContents() {

        this.bulletsPoint1 = this.add.image(this.cameras.main.width * .22,this.cameras.main.height * .4,"bulletsPoint").setScale(0.35);
        this.bulletsPoint2 = this.add.image(this.cameras.main.width * .22,this.cameras.main.height * .47,"bulletsPoint").setScale(0.35);
        this.bulletsPoint3 = this.add.image(this.cameras.main.width * .22,this.cameras.main.height * .52,"bulletsPoint").setScale(0.35);

        this.howtoplayTitle =  this.add.text(
            this.cameras.main.width * .5, 
            this.cameras.main.height * .335, 
            "HOW TO PLAY")
            .setFontFamily("sourceSans")
            .setColor("#FFFFFF")
            .setFontSize(40)
            .setOrigin(0.5)
            .setWordWrapWidth(600);


        this.pointOne =  this.add.text(
                this.cameras.main.width * .53, 
                this.cameras.main.height * .41, 
                "Spend 100 points to play the game")
                .setFontFamily("sourceSans")
                .setColor("#FFFFFF")
                .setFontSize(45)
                .setOrigin(0.5)
                .setWordWrapWidth(550);

        this.pointTwo =  this.add.text(
                this.cameras.main.width * .51, 
                this.cameras.main.height * .47, 
                "Get to spin the slotmachine")
                .setFontFamily("sourceSans")
                .setColor("#FFFFFF")
                .setFontSize(45)
                .setOrigin(0.5)
                .setWordWrapWidth(550);

        this.pointThree =  this.add.text(
                this.cameras.main.width * .52, 
                this.cameras.main.height * .52, 
                "Earn rewards based on the matches you got")
                .setFontFamily("sourceSans")
                .setColor("#FFFFFF")
                .setFontSize(45)
                .setOrigin(0.5)
                .setWordWrapWidth(550);


    }


    fadeHowToPlayScreen() {

        this.pointOne.destroy();
        this.pointTwo.destroy();
        this.pointThree.destroy();

        this.bulletsPoint1.destroy();
        this.bulletsPoint2.destroy();
        this.bulletsPoint3.destroy();

        this.howtoplayTitle.destroy();

        var _self = this;

            var tweenInstructionOne = this.tweens.add({
                targets: this.gamePlayBG,
                duration: 200,
                alpha: 1, 
                ease: "Sine.Out",
                onComplete: function () {
                    if( isGameStarted == false) {
                        isGameStarted = true;
                        _self.addSpinBtn();
                        _self.loadSlotContents();
                        _self.moveInUI();
                        _self.addConfirmationPopup();
                    } else  if(isGameStarted == true) {
                        _self.addConfirmationPopup();
                    }
                 }
            });



        var tweenInstructionOne = this.tweens.add({
            targets: [this.bg, this.logo, this.howToPlayPanel, this.playNowBtn],
            duration: 500,
            x: -this.cameras.main.width * 1.5, 
            ease: "Sine.Out",
            onComplete: function () {
                _self.bg.destroy();
                _self.logo.destroy();
                _self.howToPlayPanel.destroy();
                _self.playNowBtn.destroy();
            }
        });

    }


    addHUD() {

        this.HUDbar = this.add.rectangle(this.cameras.main.width * .5,this.cameras.main.height * .9, 4000, 150, 0x000C30);


        var _self = this;
        this.gameMode = this.add.image(this.cameras.main.width * .25,this.cameras.main.height * .9, "gameModeIcon").setScale(0.35).setInteractive().on('pointerdown', () => {
            _self.greyBackground.destroy();
            _self.confirmPopup.destroy();
            _self.rewardsTitleText.destroy();
            _self.rewardMode.setInteractive().setAlpha(1);
            _self.gameMode.disableInteractive();
            _self.gameMode.setAlpha(0.5)

        });


        this.rewardMode = this.add.image(this.cameras.main.width * .7,this.cameras.main.height * .9, "rewardModeIcon").setScale(0.35).setInteractive().on('pointerdown', () => {
            _self.rewardMode.disableInteractive();
            _self.rewardMode.setAlpha(0.5)
            _self.addRewardPanel();
        });

        if(sceneMode = "gameMode") {
            this.gameMode.disableInteractive().setAlpha(0.5);
        }

    }


    addRewardPanel() {

        this.greyBackground  = this.add.image(this.cameras.main.width * .5,this.cameras.main.height * .5, "greyOverlay").setScale(1.5).setAlpha(0.35);

        this.confirmPopup = this.add.image(this.cameras.main.width * .5,this.cameras.main.height * .4, "howtoplayPanel").setScale(0.5,0.5).setAlpha(1).setScale(0.3);

        this.gameMode.setInteractive().setAlpha(1);

        this.rewardsTitleText = this.add.text(
            this.cameras.main.width * .5, 
            this.cameras.main.height * .205, 
            "REWARDS")
            .setFontFamily("sourceSans")
            .setColor("#FFFFFF")
            .setFontSize(40)
            .setOrigin(0.5)
            .setWordWrapWidth(900);
    }



    loadSlotContents() {
        console.warn("loadSLotCOntents")
        var _self = this;

        this.slotBG = this.add.image(this.cameras.main.width * .5, this.cameras.main.height * .43 , "slotBG").setScale(0.34,0.27);

        this.inGamelogo = this.add.image(this.cameras.main.width * .5, this.cameras.main.height * .2, "logo").setScale(.35).setInteractive().on('pointerdown', () => {
            _self.triggerWinMannually = true;
        });


        scene = new THREE.Scene();
        let fov = 100;
        let aspect = this.cameras.main.width/this.cameras.main.height;
        let near = 0.1;
        let far = 1000;
       
		camera = new THREE.PerspectiveCamera(fov, aspect, near, far);

	    camera.position.set(0, 0, 60);


        renderer = new THREE.WebGLRenderer({
			canvas: this.sys.game.canvas,
			context: this.sys.game.context,
			antialias: false
		});

		 renderer.autoClear = false;

        view = this.add.extern();

        view.render = () => {
            console.log("updateCall")

            if(gameMode == "play") {

    if ((gameState < 4 || spinningStarted) && !spinEnded) {
        for (var i = 0; i < gameState; i++) {
            if (i % 2 == 0) {
                meshes[i].rotation.x += 0.010 * ((i + 1) ^ 2 * 12);
            } else {
                meshes[i].rotation.x -= 0.010 * ((i + 1) ^ 2 * 12);
            }
        }
    } else if (spinEnded) {
        switch (gameState) {
            case 3:
                for (let i = 0; i < 3; i++) {
                    meshes[i].rotation.x += 0.010 * ((i + 1) ^ 2 * 12);
                }
                meshes[3].rotation.x = stopPos[3];
                break;
            case 2:
                for (let i = 0; i < 2; i++) {
                    meshes[i].rotation.x += 0.010 * ((i + 1) ^ 2 * 12);
                }
                meshes[2].rotation.x = stopPos[2];
                break;
            case 1:
                for (let i = 0; i < 1; i++) {
                    meshes[i].rotation.x += 0.010 * ((i + 1) ^ 2 * 12);
                }
                meshes[1].rotation.x = stopPos[1];
                break;
            case 0:
                for (let i = 0; i < 0; i++) {
                    meshes[i].rotation.x += 0.010 * ((i + 1) ^ 2 * 12);
                }
                meshes[0].rotation.x = stopPos[0];
                break;
            default:
                if (gameState > 0) {
                    for (let i = 0; i < 4; i++) {
                        if (i % 2 == 0) {
                            meshes[i].rotation.x += 0.010 * ((i + 1) ^ 2 * 12);
                        } else {
                            meshes[i].rotation.x -= 0.010 * ((i + 1) ^ 2 * 12);
                        }
                    }
                }
                break;
        }

    }

			//  This is important to retain GL state between renders
			renderer.state.reset();

         //   var id =  requestAnimationFrame( animate );


			renderer.render(scene, camera);
}

		};


        // RectAreaLightUniformsLib.init();
  
        geometry = new THREE.CylinderGeometry(8,8,13,128,128)

        geometry.computeFaceNormals();
        geometry.computeVertexNormals();
        loader = new THREE.TextureLoader(loadBar);
    
        material = new THREE.MeshStandardMaterial({
            map: loader.load(gInstance.getSlotMachineMat()),
        });
        meshes = [];

        var startXPos = -19.3;
        for (var i = 0; i < SLOT_NUMBER; i++) {
            meshes[i] = new THREE.Mesh(geometry, material);
            meshes[i].name = 'slot_' + i;
            console.log(meshes[i].name)
            meshes[i].position.x = startXPos;
            meshes[i].rotation.z = Math.PI / 2;
            meshes[i].position.y = 10;
            meshes[i].rotation.x = xRotationArr[i];
            spinnedSymbols[i] = i + 1;
            scene.add(meshes[i]);
            startXPos += 13;
        }
    
        const color = 0xF7F3E9;
        const intensity = 1;
        const light = new THREE.AmbientLight(color, intensity);
        light.position.set(0, 0, 50)
        scene.add(light);
        //camera.position.z = 60;
    
        x = x - 0;
        if (x <= 0) {
            return;
        }

        var _self = this;
        this.leftArrow = this.add.image(this.cameras.main.width * .08,this.cameras.main.height * .42,"leftArrow").setScale(0.35);

        this.rightArrow = this.add.image(this.cameras.main.width * .92,this.cameras.main.height * .42,"leftArrow").setScale(0.35).setFlipX(true);

    }


    addSpinBtn() {
        var _self = this;
        this.spinBtn = this.add.image(this.cameras.main.width * .51,this.cameras.main.height * .715, "spinBtn").setScale(.5).setInteractive().on('pointerdown', () => {
            _self.spinBtn.setAlpha(0.5)
            currentSpinRound++;
            isGameOver = false;
            _self.spinBtn.disableInteractive();
            _self.startSpinning();
            _self.deductLifeLine()
        });
    }


    destroySlotContents() {
       

    //     this.slotBG.destroy();
    //     this.leftArrow.destroy();
    //     this.rightArrow.destroy();
    //   gameMode = "pause";
    //     while(scene.children.length > 0){ 
    //         scene.remove(scene.children[0]); 
    //     }

    //     scene = null;
    //     this.projector = null;
    //     camera = null;
    //     view = null;
    //     // scene.clear()
    //   //  controls = null;
    //     geometry = null;
    //     loader = null;
    //     material = null;
    //     meshes.length = 0;
    //     renderer = null;
    }

    addConfirmationPopup() {
        var _self = this;
        this.greyOverlay = this.add.image(this.cameras.main.width * .5,this.cameras.main.height * .5, "greyOverlay").setScale(1.5).setAlpha(0.5)
        this.confirmPopup = this.add.image(-this.cameras.main.width * 1.65,this.cameras.main.height * .5, "popupPanel").setScale(0.5).setAlpha(0);

        var tweenRIghtUI = this.tweens.add({
            targets: this.confirmPopup,
            x: this.cameras.main.width * .5,
            alpha: 1, 
            ease: "Sine.Out",
            duration: 300,
            onComplete: function () {
                _self.addPopupContents();
            }
        });
    }

    deductLifeLine() {
        var tweenRIghtUI = this.tweens.add({
            targets: this.heartsArr[currentSpinRound - 1],
            scale: 0, 
            ease: "Sine.Out",
            duration: 300, 
            onComplete: function () {
            }
        });
        
    }


    navigateToHowToPlay() {

        this.confirmPopup.destroy();
        this.coins.destroy();
        this.cancelBtn.destroy();
        this.confirmBtn.destroy();
        this.greyOverlay.destroy();
        this.confirmText.destroy();
        this.confirmText2.destroy();

        var _self = this;
        this.bg = this.add.image(this.cameras.main.width * .5, this.cameras.main.height * .5, "bg").setScale(2);
        this.logo = this.add.image(this.cameras.main.width * .5, this.cameras.main.height * .2, "logo").setScale(.4);


        this.howToPlayPanel = this.add.image(this.cameras.main.width * .5, this.cameras.main.height * .5, "howtoplayPanel").setScale(0.25)
        this.addHowToPlayTextContents();
        
        this.playNowBtn = this.add.image(this.cameras.main.width * .5,this.cameras.main.height * .81, "playNow").setScale(0.5).setInteractive().on('pointerdown', () => {
            _self.fadeHowToPlayScreen();
        });
    }

    proceedWithGamePlay() {
        currentSpinRound = 0;
        sceneMode = "gameMode"
        gameMode = "play";
        this.addHUD();
        this.spinBtn.setInteractive();
        this.greyOverlay.destroy();
        this.confirmPopup.destroy();
        this.cancelBtn.destroy();
        this.confirmBtn.destroy();
        this.deductPoints();
        this.spinBtn.setAlpha(1)
        this.coins.destroy();
        this.confirmText.destroy();
        this.confirmText2.destroy();
    }

    deductPoints() {
        var _self = this;
        if(points > 0) {
        var currentPoint = points - 100;
        var startTimeInterval = setInterval(function () {
            if(points > currentPoint) {
            points--;
            _self.pointsText.setText(points);
            }
        }, 10)
    } else  {
        this.addConfirmationPopup();
        this.confirmBtn.disableInteractive().setAlpha(0.5);
    }
    }

    addPopupContents() {
        var _self = this;
        this.cancelBtn = this.add.image(this.cameras.main.width * 0.34,this.cameras.main.height * .6, "cancelBtn").setAlpha(0).setScale(0.5).setInteractive().on('pointerdown', () => {
            _self.navigateToHowToPlay();
        });
        var tweenRIghtUI = this.tweens.add({
            targets: this.cancelBtn,
            alpha: 1, 
            ease: "Sine.Out",
            duration: 300,
            onComplete: function () {
            }
        });
        
        this.confirmBtn = this.add.image(this.cameras.main.width * 0.66, this.cameras.main.height * .6, "confirmBtn").setAlpha(0).setScale(0.5).setInteractive().on('pointerdown', () => {
            _self.proceedWithGamePlay();
        });


        var tweenRIghtUI = this.tweens.add({
            targets: this.confirmBtn,
            alpha: 1, 
            ease: "Sine.Out",
            duration: 300,
            onComplete: function () {
                if(points <= 0) {
                    _self.confirmBtn.setAlpha(0.35).disableInteractive();
                }
            }
        });


        this.coins = this.add.image(this.cameras.main.width * .5,this.cameras.main.height * .45,"coins").setScale(0.25);

        this.confirmText = this.add.text(
            this.cameras.main.width * .5, 
            this.cameras.main.height * .49, 
            "YOU NEED TO SPEND 100 POINTS")
            .setFontFamily("sourceSans")
            .setColor("#FFFFFF")
            .setFontSize(50)
            .setOrigin(0.5)
            .setWordWrapWidth(900);

        this.confirmText2 = this.add.text(
            this.cameras.main.width * .54, 
            this.cameras.main.height * .52, 
            "TO START THE GAME")
            .setFontFamily("sourceSans")
            .setColor("#FFFFFF")
            .setFontSize(50)
            .setOrigin(0.5)
            .setWordWrapWidth(900);
    }


    startSpinning() {
          var _self = this;
          gameState = 0;
          spinEnded = false
        var startTimeInterval = setInterval(function () {
            if (gameState < 4)
                gameState++;
            if (gameState == 3) {
                _self.randomizeSymbolStop();
                setTimeout(function () {
                    if(spinEnded != true) {
                        // alert("spinEnded")
                    clearInterval(startTimeInterval);
                    spinningStarted = false;
                    spinEnded = true;
                    _self.startEndTimer(); 
                    }
                }, spinTime)
                spinningStarted = true;
            }
        }, 500)
    }


    startEndTimer() {
        var _self = this;
        var endSpinTimer = setInterval(function () {
            if (gameState >= 0) {
                gameState--;
                if (gameState == 0){
                    console.log("gams")
                    _self.checkWinning();
                }
            } else {
                clearInterval(endSpinTimer);
            }
        }, 1000)
    }

    addresultPanel () {
        // stopPos.length = 0;
        if(!isGameOver) {
        isGameOver = true;
        var _self = this;
        console.log("isWon",isWon)

        if(isWon == false) {
            this.tryAgain = this.add.image(-this.cameras.main.width *  1.2 , this.cameras.main.height * .6, "tryAgainPng");
            var tweenInstructionOne = this.tweens.add({
                targets: this.tryAgain,
                duration: 200,
                x: this.cameras.main.width *  0.5, 
                ease: "Sine.Out",
                onComplete: function () {
                    // _self.destroySlotContents();
                    var self = _self;
                    _self.tweens.add({
                            targets: _self.tryAgain,
                            duration: 200,
                            x: _self.cameras.main.width *  2.5, 
                            ease: "Linear",
                            delay: 2000,
                            onComplete: function () {
                                self.tryAgain.destroy();
                                gameState = 0;
                                self.verifySpinCount();
                    } 
                })
            }
        })
        } else {
            this.winPopup = this.add.image(-this.cameras.main.width * 2.5,this.cameras.main.height * .5,"winPopup").setScale(.35).setAlpha(0);
            
            var tweenRIghtUI = this.tweens.add({
                targets: this.winPopup,
                x: this.cameras.main.width * .5,
                alpha: 1, 
                ease: "Sine.Out",
                duration: 300,
                onComplete: function () {
                    var self = _self;
                    self.addRandomGiftVoucher();
                    _self.tweens.add({
                            targets: [_self.winPopup,self.gift],
                            duration: 200,
                            x: _self.cameras.main.width *  2.5, 
                            ease: "Linear",
                            delay: 2000,
                            onComplete: function () {
                                self.winPopup.destroy();
                                gameState = 0;
                                self.verifySpinCount();
                                self.gift.destroy();
                    } 
                })
                }
            });
            
            //Add win popup here
        }

    }
        console.log("resultpanelAdded",winSymbolIndex,isWon)
    }


    addRandomGiftVoucher() {
        var randomNumber = Math.floor(Math.random() * this.giftArr.length);
        this.gift = this.add.image(this.cameras.main.width * .5, this.cameras.main.height * .52, this.giftArr[randomNumber]).setScale(0.35)
    }


    verifySpinCount() {
        if(currentSpinRound < 3) {
            gameMode = "play"
          //  this.loadSlotContents();
            this.spinBtn.setInteractive(true);
            this.spinBtn.setAlpha(1);
        } else if(currentSpinRound == 3)  {
            this.addConfirmationPopup();
            this.addAttempts();
        }
    }


    checkWinning() {

        winSymbolIndex = 0;
        for(let i=0;i<1;i++) {
            for(let j=i+1;j<stopPos.length;j++) {
                if(stopPos[i] == stopPos[j]) {
                    winSymbolIndex++;
                }
            }
        }

        if(winSymbolIndex == 3)
            isWon = true;
        else
            isWon = false;
            this.addresultPanel();

    }

    randomizeSymbolStop(){
    var currentDummyWin, dummyWin;
    if (triggerWinMannually == true) {
        dummyWin = Math.floor(Math.random() * 3);
        currentDummyWin = xRotationArr[dummyWin];
    }

    for (let i = 0; i < SLOT_NUMBER; i++) {
        let currentRandomNumber = Math.floor(Math.random() * xRotationArr.length);
        let finalSymbolValue = spinnedSymbols[currentRandomNumber];
        let currentRandomVal;

        if (!triggerWinMannually)
            currentRandomVal = xRotationArr[currentRandomNumber];
        else
            currentRandomVal = currentDummyWin;


        symbolsArrAfterSpin.push(finalSymbolValue);

        stopPos[i] = currentRandomVal;
        console.log("s",stopPos)

     }
    }

    moveInUI() {
        this.attemptsUI = this.add.image(this.cameras.main.width * 1.5, this.cameras.main.height * .07, "uiBoard").setScale(.5).setAlpha(0);
        this.pointsUI = this.add.image(-this.cameras.main.width * 1.5, this.cameras.main.height * .07, "uiBoard").setScale(.5).setAlpha(0).setFlipX(true);
        var _self = this;

        var tweenRIghtUI = this.tweens.add({
            targets: this.attemptsUI,
            x: this.cameras.main.width * .79,
            alpha: 1, 
            ease: "Sine.Out",
            duration: 300,
            onComplete: function () {
                _self.addPoints();
            }
        });

        var tweenLeftUI = this.tweens.add({
            targets: this.pointsUI,
            x: this.cameras.main.width * .21,
            alpha: 1, 
            ease: "Sine.Out",
            duration: 300,
            onComplete: function () {
                _self.addAttempts();
            }
        });

    }

    addPoints() {
        this.pointsText = this.add.text(
            this.cameras.main.width * .25, 
            this.cameras.main.height * .067, 
            points)
            .setFontFamily("sourceSans")
            .setColor("#FFFFFF")
            .setFontSize(55)
            .setOrigin(0.5)
            .setWordWrapWidth(900);
    }

    addAttempts() {
        this.heartsArr = [];
        var heartIcon1 = this.add.image(this.cameras.main.width * .7,this.cameras.main.height * .07,"heartIcon").setScale(0.4)
        var heartIcon2 = this.add.image(this.cameras.main.width * .77,this.cameras.main.height * .07,"heartIcon").setScale(0.4)
        var heartIcon3 = this.add.image(this.cameras.main.width * .84,this.cameras.main.height * .07,"heartIcon").setScale(0.4)
        this.heartsArr.push(heartIcon1);
        this.heartsArr.push(heartIcon2);
        this.heartsArr.push(heartIcon3);
    }

    moveOutUI() {

    }





}




var config = {
    type: Phaser.WEBGL,
    transparent: true,
    antialias: true,
    width: 1080,
    height: 2100,
    parent: "game",
    scale: {
        mode: Phaser.Scale.HEIGHT_CONTROLS_WIDTH,
        autoCenter: Phaser.Scale.CENTER_BOTH
    },
    loader: {
        crossOrigin: true
    },
    devicePixelRatio: window.devicePixelRatio,
    scene: [LoadScene,GameScene],
    physics: {
        default: "arcade",
        arcade: {
            gravity: { x: 0, y: 0 }
        },
        enableBody: true
    }
}





new Game();















